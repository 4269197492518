import * as constants from './constants';

export const id = {
  im: /^im-\d{4,}$/,
  imGroup: /im-\d{4,}/g,
  public: new RegExp(`(wires|${constants.PROPERTIES.getKeys().join('|')})\\/\\w+\\/\\w+\\/?\\w+`),
  wires: /wires\/\w+\/\w+\/?\w+/,
  asset: /^[0-9a-fA-F]{32}$/,
  legacy:
    /^([a-zA-Z0-9]{32,32}$)|(^[0-9]{6,10}$)|(^imut?-[0-9]{16,18})|(20\d{6}-\d{6}-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}-[a-zA-Z0-9]+)$/,
};

/**
 * Date format: yyyy-MM-dd
 */
export const date = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;

export const unicode = {
  null: /\\u0000/,
  lineSeparators: /\n|\r/,
};

export const pipe = /\|/;

export const quoted = /"(.*?)"/g;

export const chartSourceRegex = /^charts.(dev.|int.)?dowjones.io$/i;

export const specialCharacters = /(!|\^|&|\(|\)|{|}|\[|\]|<|>|\?|~|=|\\|:)/;

export const asteriskAtTheEnd = /^[^*]*\*$/;

export const hyphenAtStart = /^-(.*?)/;

export const asterisk = /(\*)/;

export const m2mImportSource = (scope: string) => new RegExp(`^(${scope.split('.').join('\\.')})_(local_|dev_|int_)?([a-z0-9]{0,10})$`, "i");

export const newGridIdRegex = /W(?:[a-zA-Z]{1})-(?:[a-zA-Z]{2,8})-[0-9]{10,12}/i //L,A,D,Q or P (for local, dev, QA, Prod)

export const liveCoverageIdRegex = (property: string) => new RegExp(`^lc-${property}-([a-zA-Z0-9\\-_]{5,100})$`, "i");
export const liveCoverageIdRegexWithCardId = /^([a-zA-Z0-9\-_]{5,100})(?<cardId>-[a-zA-Z\d]{20,20})$/i;

export const hexColor = /^#(?:[0-9a-fA-F]{6}){1}$/;

/**
 * Matches whitespace or any character not allowed in a slug (anything other than lowercase letters, digits, underscores, dots, and hyphens).
 */
export const slugReplace = /\s+|[^a-z0-9_.-]+/gi;

/**
 * Validates a slug against a pattern allowing letters, digits, hyphens, dots, and underscores, up to 32 characters.
 */
export const slugValidate = /^[a-zA-Z0-9_./-]{0,32}$/;